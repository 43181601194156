<template>
        <div :class="{'aside-mini-wrap':isCollapse}" class="aside-wrap" ref="aside">
            <el-menu :default-active="onRoutes" :collapse-transition="false" router
                     mode="vertical" :unique-opened="true"
                     :collapse="isCollapse">
                <el-menu-item :index="'/'+defaultIndex" @click.native="select('首页')">
                    <i class="el-icon-menu"  style="font-size: 16px"></i>
                    <span slot="title">首页{{defaultIndex}}</span>
                </el-menu-item>
                <template v-for="(item,index) in menuList">
                    <template v-for="(subItem,subIndex) in item.menu_list">
                        <template v-if="subItem.menu_list">
                            <el-submenu :index="(subItem.resourceId).toString()">
                                <template slot="title">
                                    <i :class="subItem.resourceIcon||'el-icon-user-solid'"></i>
                                    <span slot="title" v-show="!isCollapse">{{subItem.resourceName}}</span>
                                </template>
                                <el-menu-item v-for="(subItem1,i) in subItem.menu_list" :key="i" @click.native="select(subItem1.resourceName)" :index="setMenuUrl(subItem1)">
                                    <i :class="subItem1.resourceIcon"></i>
                                    {{subItem1.resourceName}}
                                </el-menu-item>
                            </el-submenu>
                        </template>
                        <template v-else>
                            <el-menu-item @click.native="select(subItem.resourceName)" :index="setMenuUrl(subItem)">
                                <i :class="subItem.resourceIcon||'el-icon-user-solid'"></i>
                                <span slot="title">{{subItem.resourceName}}</span>
                            </el-menu-item>
                        </template>
                    </template>
                </template>
            </el-menu>
        </div>
</template>

<script>

export default {
    data() {
        return {
            menu:[],
            menuList:[],
            defaultIndex:this.$route.path.split('/')[1],
            defaultMate: this.$route.meta.menuIndex,
            styleObject:{},
            onRoutes:'',
            clientWidth:document.body.clientWidth,
            showMenu:false,
        }
    },
    props:['isCollapse'],
    watch:{
        $route(){
            this.defaultIndex = this.$route.path.split('/')[1]
            this.defaultMate = this.$route.meta.menuIndex
            this.menuList = this.menu.filter(item=>{
                return item.resourceHtmlUrl==this.defaultIndex
            })
            this.onRoutes = this.defaultMate?'/'+this.defaultIndex+'/'+this.defaultMate:this.$route.fullPath
        }
    },
    mounted(){
        this.onRoutes = this.defaultMate?'/'+this.defaultIndex+'/'+this.defaultMate:this.$route.fullPath
        this.getMenu();
    },
    methods:{
        getMenu(){
            this.$apiGet('lechun-baseservice/auth/getSysUserMenu',{'serviceId':'LECHUN-CSMS'}).then(res => {
                this.menu = res.map(item=>{
                    let sysHtmlUrlArr = item.resourceHtmlUrl.split('/')
                    item.resourceHtmlUrl = sysHtmlUrlArr[sysHtmlUrlArr.length-1]
                    return item
                })
                this.$store.commit('MENU_LIST',this.menu)

                this.menuList = res.filter(item=>{
                    let sysHtmlUrlArr = item.resourceHtmlUrl.split('/')
                    item.resourceHtmlUrl = sysHtmlUrlArr[sysHtmlUrlArr.length-1]
                    return item.resourceHtmlUrl==this.defaultIndex
                })
            })
        },
        setMenuUrl(item){
            let sysHtmlUrlArr = item.parentResourceHtmlUrl.split('/')
            item.parentResourceHtmlUrl = sysHtmlUrlArr[sysHtmlUrlArr.length-1]
            let url = (item.resourceHtmlUrl||'').substring(0,1)=="/"?item.resourceHtmlUrl:'/'+item.resourceHtmlUrl
            let parentPath = '/'+(item.parentResourceHtmlUrl||this.defaultIndex)
            return parentPath+url
        },
        select(item){
            console.log(1,item)
            this.showMenu = false
            this.$emit('closeAside')
            this.$store.commit('ADD_TAB_VIEW',{name:item,path:this.onRoutes})
        },
        showMenuFun(){
            console.log(this.showMenu)
            // this.showMenu = !this.showMenu
        },
        navTab(){
            this.isCollapse = !this.isCollapse
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import "../assets/css/public";
@import "../assets/css/mixin";
$mainColor:#209e91;
$asideFont:12px;
$asideIconFont:12px;
.el-scrollbar{
    height: 100%;
}
.el-aside{
    height: 100%;
    margin: 0;
    border-radius: 8px;
    transition:width .3s;
    overflow-x: visible!important;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.25);
    overflow-y: scroll!important;
}
.aside-wrap{
    width: $asideWidth;
    overflow-y: scroll;
    scrollbar-width: none;
    @include aside_bg_color();
    >.el-menu{
        border: none;
        border-radius: 8px;
        background: transparent;
        .el-menu-item{
            font-size: $asideFont;
            @include aside_font_color();
            i{
                color: inherit;
            }
            &.is-active,&.is-active:hover,&.is-active:focus{
                background: $mainColor;
                color: #ffffff;
            }
            &:hover,&:focus{
                color: $mainColor;
                background: transparent;
            }
        }
        .el-submenu{
            >.el-menu{
                @include aside_submenu_bg_color();
                .el-menu-item{
                    padding: 0 0 0 50px !important;
                }
            }
            .el-submenu__title{
                i{
                    color: inherit;
                }
                font-size: $asideFont;
                @include aside_font_color();
                &:hover{
                    color: $mainColor;
                    background: transparent;
                }
            }
            &.is-active{
                .el-submenu__title,.el-submenu__title i{
                    color: $mainColor;
                }
            }
        }
    }
}
.aside-mini-wrap{
    width: $asideMiniWidth;
}
.aside-switch{
    margin-right: 18px;
    font-size: 24px;
    color: #209e91;
}
.iconfont{
    display: inline-block;
}
.trans-enter-active{
    animation: rotateT .5s ease forwards;
}
@keyframes rotateT {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(90deg);
    }
}

</style>
